'use client';

import { useEffect } from 'react';
import {
  FieldValue,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import 'react-quill/dist/quill.snow.css';

import dynamic from 'next/dynamic';

import * as S from './styles';

const ReactQuill = dynamic(() => import('react-quill'), { ssr: false });

type EditorProps = {
  name: string;
  label: string;
  remove?: Array<
    'bold' | 'italic' | 'underline' | 'strike' | 'blockquote' | 'link'
  >;
  register: UseFormRegister<FieldValue<FieldValues>>;
  setValue: UseFormSetValue<FieldValue<FieldValues>>;
  watch: UseFormWatch<FieldValue<FieldValues>>;
  error?: boolean;
  errorMessage?: string;
};

export const Editor = ({
  name,
  label,
  register,
  setValue,
  watch,
  error,
  errorMessage,
  remove = [],
}: EditorProps) => {
  useEffect(() => {
    register(name, { required: true, minLength: 15 });
  }, [register]);

  const onEditorStateChange = (editorState: string) => {
    const cleanedContent =
      editorState?.replace(/<(.|\n)*?>/g, '').trim() === '' ? '' : editorState;
    setValue(name, cleanedContent);
  };

  const editorContent = watch(name);

  let modules = {};
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link'].filter(
        (item) => !remove.includes(item as never)
      ),
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  return (
    <>
      {label && <S.Label>{label}</S.Label>}
      <S.EditorContainer>
        <ReactQuill
          theme="snow"
          value={editorContent}
          onChange={onEditorStateChange}
          modules={!remove.length ? undefined : modules}
        />
      </S.EditorContainer>
      {error && errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
    </>
  );
};
