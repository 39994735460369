import * as Tooltip from '@radix-ui/react-tooltip';
import styled, { css, keyframes } from 'styled-components';

const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const tooltipAnimations = css`
  &[data-state='delayed-open'][data-side='top'] {
    animation: ${slideUpAndFade} 0.3s forwards;
  }

  &[data-state='delayed-open'][data-side='right'] {
    animation: ${slideLeftAndFade} 0.3s forwards;
  }

  &[data-state='delayed-open'][data-side='bottom'] {
    animation: ${slideDownAndFade} 0.3s forwards;
  }

  &[data-state='delayed-open'][data-side='left'] {
    animation: ${slideRightAndFade} 0.3s forwards;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  ${tooltipAnimations}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    ${tooltipAnimations}
    font-weight: ${theme.typography.fontWeight.medium};
    font-size: ${theme.typography.fontSize['12'].size};
    border: none;
    border-radius: ${theme.radius.xs};
    padding: ${theme.spacing['08']} ${theme.spacing['12']};
    box-shadow: ${theme.shadows.xl};

    &.contrast-high {
      background-color: ${theme.colors.black};
      color: ${theme.colors.white};
    }
    &.contrast-low {
      background-color: ${theme.colors.white};
      color: ${theme.colors.gray[900]};
    }
  `}
`;

export const TooltipContent = styled(Tooltip.Content)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    border-radius: ${theme.radius.xs};
    padding: ${theme.spacing['12']};
    font-size: ${theme.typography.fontSize['12'].size};
    font-weight: ${theme.typography.fontWeight.medium};
    box-shadow: ${theme.shadows.xl};

    &.contrast-high {
      background-color: ${theme.colors.black};
    }
    &.contrast-low {
      background-color: ${theme.colors.white};
    }
    &.modal-false {
      z-index: 1;
    }
    &.modal-true {
      z-index: 9;
    }
  `}
`;

export const Arrow = styled(Tooltip.Arrow)`
  ${({ theme }) => css`
    &.contrast-high {
      fill: ${theme.colors.black};
    }
    &.contrast-low {
      fill: ${theme.colors.white};
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing['04']};
    &.contrast-high {
      color: ${theme.colors.white};
    }

    &.contrast-low {
      color: ${theme.colors.gray['900']};
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    line-height: ${theme.typography.fontSize['12'].lineHeight};
    font-weight: ${theme.typography.fontWeight.regular};
    overflow: hidden;
    max-width: 300px;
    display: inline-block;
    &.contrast-high {
      color: ${theme.colors.white};
    }

    &.contrast-low {
      color: ${theme.colors.gray['700']};
    }
  `}
`;
