'use client';

import { ReactNode } from 'react';

import * as TooltipRadix from '@radix-ui/react-tooltip';
import clsx from 'clsx';

import * as S from './styles';

type Contrast = 'high' | 'low';
type ModalContent = 'true' | 'false';

type TooltipProps = {
  label: string | ReactNode;
  description: string | ReactNode;
  title?: string;
  modal?: ModalContent;
  contrast?: Contrast;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
};

const Tooltip = ({
  contrast = 'low',
  label,
  modal = 'false',
  description,
  title = '',
  isOpen,
  onOpenChange,
}: TooltipProps) => {
  const commomClassName = clsx(`contrast-${contrast}`, `modal-${modal}`);

  return (
    <TooltipRadix.Provider>
      {isOpen !== undefined && onOpenChange !== undefined ? (
        <TooltipRadix.Root open={isOpen} onOpenChange={onOpenChange}>
          <TooltipContentAndTrigger
            commomClassName={commomClassName}
            label={label}
            title={title}
            description={description}
          />
        </TooltipRadix.Root>
      ) : (
        <TooltipRadix.Root>
          <TooltipContentAndTrigger
            commomClassName={commomClassName}
            label={label}
            title={title}
            description={description}
          />
        </TooltipRadix.Root>
      )}
    </TooltipRadix.Provider>
  );
};

const TooltipContentAndTrigger = ({
  commomClassName,
  label,
  title,
  description,
}: any) => (
  <>
    <TooltipRadix.Trigger asChild>
      {typeof label === 'string' ? (
        <S.Button className={commomClassName}>{label}</S.Button>
      ) : (
        <S.Content className={commomClassName}>{label}</S.Content>
      )}
    </TooltipRadix.Trigger>

    <TooltipRadix.Portal>
      <S.TooltipContent className={commomClassName}>
        <S.Title className={commomClassName}>{title}</S.Title>
        <S.Description className={commomClassName}>{description}</S.Description>
        <S.Arrow className={commomClassName} />
      </S.TooltipContent>
    </TooltipRadix.Portal>
  </>
);

export { Tooltip };
